<template>
    <div>
        <base-header class="pb-6">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h6 class="h2 text-white d-inline-block mb-0"></h6>
                </div>      
            </div>
        </base-header>
        <div class="container-fluid mt--6">
            <div class="card mb-4">
                <div class="card-header mt--4">
                    <div class="row">
                        <div class="col">
                            <h3 class="mb-0 text-center">{{ tt('mcr_detail') }}</h3>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-6">
                            <h3>{{ tt('mcr_information') }}</h3>
                        </div>
                        <div v-if="formtype == 'edit'" class="col-6 text-right">
                            <base-button size="sm" type="default" @click="editMcrInformation">{{ tt('edit') }}</base-button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <table class="table table-responsive-md table-bordered">
                                <tbody>
                                    <tr>
                                        <th class="bg-secondary">{{ tt('subject') }}</th>
                                        <td colspan="3">{{ mcrInformation.subject }}</td>
                                    </tr>
                                    <tr>
                                        <th class="bg-secondary">{{ tt('type') }}</th>
                                        <td>{{ mcrInformation.form_description }}</td>
                                        <th class="bg-secondary">{{ tt('created_by') }}</th>
                                        <td>{{ mcrInformation.created_by }}</td>
                                    </tr>
                                    <tr>
                                        <th class="bg-secondary">{{ tt('status') }}</th>
                                        <td>{{ mcrInformation.status }}</td>
                                        <th class="bg-secondary">{{ tt('created_at') }}</th>
                                        <td>{{ mcrInformation.created_at }}</td>
                                    </tr>
                                </tbody>
                            </table> 
                        </div>
                    </div>   
                    <br>
                    <div class="row">
                        <div class="col">
                            <h3>{{ tt('mcr_item') }}</h3>
                        </div>
                        <div v-if="formtype == 'view'" class="col text-right">
                          <base-button size="sm" type="default" @click="tracking()">{{
                            tt("tracking")
                          }}</base-button>
                        </div>
                        <div v-if="formtype == 'edit'" class="col text-right">
                          <base-button size="sm" type="default" @click="create('a4')">{{
                            tt("add_new")
                          }}</base-button>
                        </div>
                    </div>
                    <el-table class="table-responsive table-flush" header-row-class-name="thead-light" :data="mcrItem">
                        <el-table-column :label="tt('no')" :prop="tt('no')" min-width="100px" sortable>
                            <template v-slot="{row}">
                                {{ row.id }}
                            </template>
                        </el-table-column>
                        <el-table-column v-if="mcrType != 'b6'" :label="tt('material_number')" :prop="tt('material_number')" min-width="200px" sortable>
                            <template v-slot="{row}">
                                {{ row.material_number }}
                            </template>
                        </el-table-column>
                        <el-table-column v-if="mcrType == 'a1' || mcrType == 'a2' || mcrType == 'b1' || mcrType == 'b2' || mcrType == 'b3'" :label="tt('specification')" :prop="tt('specification')" min-width="200px" sortable>
                            <template v-slot="{row}">
                                {{ row.specification }}
                            </template>
                        </el-table-column>
                        <el-table-column v-if="mcrType == 'a3' || mcrType == 'b4'" :label="tt('note')" :prop="tt('note')" min-width="200px" sortable>
                            <template v-slot="{row}">
                                {{ row.note }}
                            </template>
                        </el-table-column>
                        <el-table-column v-if="mcrType == 'a4'" :label="tt('reason')" :prop="tt('reason')" min-width="200px" sortable>
                            <template v-slot="{row}">
                                {{ row.reason }}
                            </template>
                        </el-table-column>
                        <el-table-column v-if="mcrType == 'b6'" :label="tt('consolidate_from')" :prop="tt('consolidate_from')" min-width="200px" sortable>
                            <template v-slot="{row}">
                                {{ row.prc_consolidate_from }}
                            </template>
                        </el-table-column>
                        <el-table-column v-if="mcrType == 'b6'" :label="tt('consolidate_to')" :prop="tt('consolidate_to')" min-width="200px" sortable>
                            <template v-slot="{row}">
                                {{ row.prc_consolidate_to }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="action" width="100">
                            <template v-slot="{row}">
                                <el-dropdown trigger="click" class="dropdown" @command="handleTableAction" :hide-on-click="true" v-if="authUserPermission['smartcat-attachment-update-folder'] || authUserPermission['smartcat-attachment-delete-folder'] || authUserPermission['smartcat-attachment-moveto-folder'] || authUserPermission['smartcat-attachment-update-file'] || authUserPermission['smartcat-attachment-delete-file'] || authUserPermission['smartcat-attachment-moveto-file']">
                                    <span class="btn btn-sm btn-icon-only text-light">
                                        <i class="fas fa-ellipsis-v mt-2"></i>
                                    </span>
                                    <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                        <el-dropdown-item v-if="formtype == 'view'" :command="{action:'detail', data:{btn:'detail', mcr_item_code:row.mcr_item_code,mcr_type:mcrInformation.type}}">{{ tt('detail') }}</el-dropdown-item>
                                        <!-- <el-dropdown-item :command="{action:'detail', data:{btn:'edit', mcr_item_code:row.mcr_item_code,mcr_type:mcrInformation.type}}">{{ tt('edit') }}</el-dropdown-item> -->
                                        <el-dropdown-item v-if="formtype == 'edit'" :command="{action:'detail', data:{btn:'edit', mcr_item_code:row.mcr_item_code,mcr_type:'a4'}}">{{ tt('edit') }}</el-dropdown-item>
                                        <el-dropdown-item v-if="formtype == 'edit'" :command="{action:'remove', data:{id:row.id}}">{{ tt('delete') }}</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
                <div class="card-footer pb-0 ">                           
                    <span class="float-right mt--4 mb-4">
                      Total Items {{total_mcr_item}}
                    </span>
                </div>
            </div>
            <div class="row">
                <div v-if="formtype == 'edit'" class="col text-center">
                    <router-link :to="'/material/draft-list'" class="btn btn-sm btn-dark">Draft List</router-link>  
                    <base-button size="sm" type="button" class="btn btn-lg btn-login" style="background: linear-gradient(to left,  #2e41db, #5626c7);padding-right: 40px;padding-left: 40px;color: #fff;outline-color: black;" v-on:click="sendMcr(mcr_code)" :disabled="btnSend.onLoading">
                      <span v-if="btnSend.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                      <span v-else>{{ tt('send') }}</span>
                    </base-button>
                </div>
            </div>
        </div>

        <!-- MODAL TRACKING  -->
        <tracking v-if="formTracking.show" form-show="formTracking.show" :mcr-code="mcr_code"/>
        
        <!--Form Edit Subject-->
        <validation-observer v-slot="{invalid}">
            <modal :show.sync="formMcrInformation.show">
                <template slot="header">
                    <h5 class="modal-title">{{tt('edit_subject')}}</h5>
                </template>
                <div>
                    <!-- <base-alert type="danger" v-if="errorMessage">
                        <p><strong>{{ errorMessage.message }}</strong></p>
                        <div v-if="errorMessage.data.length != 0">
                            <span v-for="message in errorMessage.data">
                                {{ message[0] }}<br>
                            </span>
                        </div>
                    </base-alert> -->
                    <label class="form-control-label">{{ tt('subject') }} <span class="text-danger">*</span></label>
                    <base-input :name="tt('subject')" :placeholder="tt('subject')" v-model="mcrInformationEdit.new_subject" rules="required"></base-input>
                </div>
                <template slot="footer">
                    <base-button type="secondary" @click="formMcrInformation.show = false">{{ tt('close') }}</base-button>
                    <base-button type="primary" v-on:click="updateMcrInformation" :disabled="btnUpdate.onLoading || invalid">
                        <span v-if="btnUpdate.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                        <span v-else>
                            {{ tt('update') }}
                        </span>
                    </base-button>
                </template>
            </modal>
        </validation-observer>
        <!--Form Edit Subject-->
        
        <modal :show.sync="form.a4.show" size="lg">
            <template slot="header">
                <h5 class="modal-title">
                    <span v-if="form.a4.type == 'add'">{{tt('add_new')}}</span>
                    <span v-else-if="form.a4.type == 'edit'">{{tt('edit')}}</span>
                    <span v-else>{{tt('detail')}}</span>
                </h5>
            </template>

            <div class="card">
                <div class="card-header bg-primary">
                    <h4 class="text-white">{{ tt('deletion_flag_material') }}</h4>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12">
                            <label class="form-control-label">{{ tt('level') }} <span class="text-danger">*</span></label>
                            <base-input :name="tt('level')" rules="required">
                                <el-select :disabled="form.a4.disabled" class="select-danger" v-model="inputDraftListA4.deletion_level" @change="changeMaterialNumberA4" :placeholder="tt('choose_level')">
                                    <el-option class="select-danger" :value="dl" :label="dl" :key="dl" v-for="dl in  deletionLevel"></el-option>
                                </el-select>
                            </base-input>
                        </div>
                        <div class="col-md-12" v-if="inputDraftListA4.deletion_level == 'Material' || inputDraftListA4.deletion_level == 'Plant' || inputDraftListA4.deletion_level == 'Storage Location' || inputDraftListA4.deletion_level == 'Valuation' || inputDraftListA4.deletion_level == 'Sales'">
                            <label class="form-control-label">{{ tt('material_number') }} <span class="text-danger">*</span></label>
                            <base-input :name="tt('material_number')" rules="required">
                                <el-select :disabled="form.a4.disabled" class="select-danger" v-model="inputDraftListA4.material_number" @change="changeMaterialNumberA4" :placeholder="tt('choose_material_number')">
                                    <el-option class="select-danger" :value="mn.material_number" :label="mn.material_number" :key="mn.material_number" v-for="mn in  materialNumber"></el-option>
                                </el-select>
                            </base-input>
                        </div>
                        <div class="col-md-12" v-if="inputDraftListA4.deletion_level == 'Plant' || inputDraftListA4.deletion_level == 'Storage Location'">
                            <label class="form-control-label">{{ tt('plant') }} <span class="text-danger">*</span></label>
                            <base-input :name="tt('plant')" rules="required">
                                <el-select :disabled="form.a4.disabled" class="select-danger" v-model="inputDraftListA4.plant_code" :placeholder="tt('choose_plant')">
                                    <el-option class="select-danger" :value="pc.plant_code" :label="pc.description" :key="pc.plant_code" v-for="pc in  plantCode"></el-option>
                                </el-select>
                            </base-input>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12" v-if="inputDraftListA4.deletion_level == 'Storage Location'">
                            <label class="form-control-label">{{ tt('storage_location') }} <span class="text-danger">*</span></label>
                            <base-input :name="tt('storage_location')" rules="required">
                                <el-select :disabled="form.a4.disabled" class="select-danger" v-model="inputDraftListA4.location_code" :placeholder="tt('choose_storage_location')">
                                    <el-option class="select-danger" :value="lc.location_code" :label="lc.location_description" :key="lc.location_code" v-for="lc in  locationCode"></el-option>
                                </el-select>
                            </base-input>
                        </div>
                        <div class="col-md-12" v-if="inputDraftListA4.deletion_level == 'Valuation'">
                            <label class="form-control-label">{{ tt('valuation_area') }} <span class="text-danger">*</span></label>
                            <base-input :name="tt('valuation_area')" rules="required">
                                <el-select :disabled="form.a4.disabled" class="select-danger" v-model="inputDraftListA4.valuation_area_code" :placeholder="tt('choose_valuation_area')">
                                    <el-option class="select-danger" :value="pc.plant_code" :label="pc.description" :key="pc.plant_code" v-for="pc in  plantCode"></el-option>
                                </el-select>
                            </base-input>
                        </div>
                        <div class="col-md-12" v-if="inputDraftListA4.deletion_level == 'Valuation'">
                            <label class="form-control-label">{{ tt('valuation_type') }} <span class="text-danger">*</span></label>
                            <base-input :name="tt('valuation_type')" rules="required">
                                <el-select :disabled="form.a4.disabled" class="select-danger" v-model="inputDraftListA4.valuation_type_code" :placeholder="tt('choose_valuation_type')">
                                    <el-option class="select-danger" :value="vt.valuation_type_code" :label="vt.valuation_type_description" :key="vt.valuation_type_code" v-for="vt in  valuationType"></el-option>
                                </el-select>
                            </base-input>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12" v-if="inputDraftListA4.deletion_level == 'Sales'">
                            <label class="form-control-label">{{ tt('sales_organization') }} <span class="text-danger">*</span></label>
                            <base-input :name="tt('sales_organization')" rules="required">
                                <el-select :disabled="form.a4.disabled" class="select-danger" v-model="inputDraftListA4.sales_org_code" :placeholder="tt('choose_sales_organization')">
                                    <el-option class="select-danger" :value="so.sales_org_code" :label="so.sales_org_description" :key="so.sales_org_code" v-for="so in  salesOrganization"></el-option>
                                </el-select>
                            </base-input>
                        </div>
                        <div class="col-md-12" v-if="inputDraftListA4.deletion_level == 'Sales'">
                            <label class="form-control-label">{{ tt('distribution_channel') }}</label><span class="text-danger">*</span>
                            <base-input :name="tt('distribution_channel')" rules="required">
                                <el-select :disabled="form.a4.disabled" class="select-danger" v-model="inputDraftListA4.distr_channel_code" :placeholder="tt('choose_distribution_channel')">
                                    <el-option class="select-danger" :value="dc.distr_channel_code" :label="dc.distr_channel_description" :key="dc.distr_channel_code" v-for="dc in  distributionChannel"></el-option>
                                </el-select>
                            </base-input>
                        </div>
                    </div>

           <!--          <div class="row">
                        <div class="col-md-6">
                            <label class="form-control-label">{{ tt('warehouse_number') }} <span class="text-danger">*</span></label>
                            <base-input :name="tt('warehouse_number')" rules="required">
                                <el-select :disabled="form.a4.disabled" class="select-danger" v-model="inputDraftListA4.warehouse_number" :placeholder="tt('choose_warehouse_number')">
                                    <el-option class="select-danger" :value="w.warehouse_number" :label="w.warehouse_description" :key="w.warehouse_number" v-for="w in  warehouse"></el-option>
                                </el-select>
                            </base-input>
                        </div>
                        <div class="col-md-6">
                            <label class="form-control-label">{{ tt('storage_type') }} <span class="text-danger">*</span></label>
                            <base-input :name="tt('storage_type')" rules="required">
                                <el-select :disabled="form.a4.disabled" class="select-danger" v-model="inputDraftListA4.storage_type_code" :placeholder="tt('choose_storage_type')">
                                    <el-option class="select-danger" :value="st.storage_type_code" :label="st.storage_type_description" :key="st.storage_type_code" v-for="st in  storageType"></el-option>
                                </el-select>
                            </base-input>
                        </div>
                    </div> -->
                    
                    <div id="accordionMpnA4">
                        <div class="card z-depth-0 bordered">
                            <div class="card-header bg-secondary border" id="headingOneMpnA4">
                                <h4 class="pt-2 pl-2 pb-2 pr-2 mb--1">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <span @click="toggleAccordion(accordion.a4.mpn.show, 'mpnA4')">{{tt('manufacturer_part_number')}}</span>
                                        </div>
                                        <div class="col-md-6 text-right">
                                            <base-button v-if="!form.a4.disabled" size="sm" type="default" @click="addMpnA4">{{ tt('add_new') }}</base-button>
                                        </div>
                                    </div>
                                </h4>
                            </div>
                            <div id="collapseMpnA4" :class="'collapse '+accordion.a4.mpn.show">
                                <div class="card-body border">
                                    <div class="table-responsive">
                                        <table class="table align-items-center table-flush table-bordered">
                                            <thead class="card-header bg-primary">
                                                <tr>
                                                    <!-- <th class="text-white">{{tt('manufacturer_code')}}</th> -->
                                                    <th class="text-white">{{tt('mpn')}}</th>
                                                    <!-- <th class="text-white">{{tt('type')}}</th>
                                                    <th class="text-white">{{tt('note')}}</th> -->
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(mpn, key) in manufacturePartNumberA4.mpn" v-bind:key="mpn.mpn">
                                                    <!-- <td>
                                                        <select :disabled="form.a4.disabled" class="form-control-sm" v-model="manufacturePartNumberA4.manufacture_code[key]">
                                                            <option value="">Choose Manufacture</option>
                                                            <option :value="m.manufacture_code" v-for="m in manufacture" v-bind:key="m.id">{{m.manufacture_name}}</option>
                                                        </select>
                                                    </td> -->
                                                    <td><input :disabled="form.a4.disabled" class="form-control form-control-sm" :name="tt('mpn')" :placeholder="tt('mpn')" v-model="manufacturePartNumberA4.mpn[key]" rules="required"></td>
                                                    <!-- <td>
                                                        <select :disabled="form.a4.disabled" class="form-control-sm" v-model="manufacturePartNumberA4.manufacture_type[key]">
                                                                <option value="">Choose Type</option>
                                                            <option :value="mt.code" v-for="mt in manrefType" v-bind:key="mt.id">{{mt.description}}</option>
                                                        </select>
                                                    </td>
                                                    <td><input :disabled="form.a4.disabled" class="form-control form-control-sm" :name="tt('note')" :placeholder="tt('note')" v-model="manufacturePartNumberA4.manufacture_note[key]" rules="required"></td> -->
                                                    <td><i v-if="!form.a4.disabled" class="fa fa-trash text-danger" @click="removeMpnA4(key)"></i></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="accordionEqiA4">
                        <div class="card z-depth-0 bordered">
                            <div class="card-header bg-secondary border" id="headingOneEqiA4">
                                <h4 class="pt-2 pl-2 pb-2 pr-2 mb--1">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <span @click="toggleAccordion(accordion.a4.eqi.show, 'eqiA4')">{{tt('equipment_&_quantity_install')}}</span>
                                        </div>
                                        <div class="col-md-6 text-right">
                                            <base-button v-if="!form.a4.disabled" size="sm" type="default" @click="addEqiA4">{{ tt('add_new') }}</base-button>
                                        </div>
                                    </div>
                                </h4>
                            </div>
                            <div id="collapseEqiA4" :class="'collapse '+accordion.a4.eqi.show">
                                <div class="card-body border">
                                    <div class="table-responsive">
                                        <table class="table align-items-center table-flush table-bordered">
                                            <thead class="card-header bg-primary">
                                                <tr>
                                                    <!-- <th class="text-white">{{tt('plant')}}</th> -->
                                                    <th class="text-white">{{tt('equipment_code')}}</th>
                                                    <!-- <th class="text-white">{{tt('quantity_install')}}</th>
                                                    <th class="text-white">{{tt('drawing_number')}}</th> -->
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(eqi, key) in equipmentQuantityInstallA4.equipment_code" v-bind:key="eqi.equipment_code">
                                                    <!-- <td>
                                                        <select :disabled="form.a4.disabled" class="form-control-sm" v-model="equipmentQuantityInstallA4.plant[key]">
                                                            <option value="">Choose Plant</option>
                                                            <option :value="pc.plant_code" v-for="pc in plantCode" v-bind:key="pc.description">{{pc.description}}</option>
                                                        </select>
                                                    </td> -->
                                                    <td>
                                                        <select :disabled="form.a4.disabled" class="form-control-sm" v-model="equipmentQuantityInstallA4.equipment_code[key]">
                                                            <option value="">Choose Equipment Code</option>
                                                            <option :value="ec.equipment_code" v-for="ec in equipmentCode" v-bind:key="ec.equipment_code">{{ec.equipment_name}}</option>
                                                        </select>
                                                    </td>
                                                    <!-- <td><input :disabled="form.a4.disabled" class="form-control form-control-sm" :name="tt('qty_install')" :placeholder="tt('qty_install')" v-model="equipmentQuantityInstallA4.qty_installed[key]" rules="required"></td>
                                                    <td>
                                                        <select :disabled="form.a4.disabled" class="form-control-sm" v-model="equipmentQuantityInstallA4.drawing_number[key]">
                                                            <option value="">Choose Type</option>
                                                            <option :value="dm.drawing_number" v-for="dm in drawingMaster" v-bind:key="dm.drawing_number">{{dm.drawing_name}}</option>
                                                        </select>
                                                    </td> -->
                                                    <td><i v-if="!form.a4.disabled" class="fa fa-trash text-danger" @click="removeEqiA4(key)"></i></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <label class="form-control-label mt-4">{{ tt('reason') }} <span class="text-danger">*</span></label>
                    <base-input :name="tt('reason')" rules="required">
                        <textarea :disabled="form.a4.disabled" class="form-control" rows="5" v-model="inputDraftListA4.reason"></textarea>
                    </base-input>
                    <label class="form-control-label mt-4">{{ tt('note') }}</label>
                    <base-input :name="tt('note')">
                        <textarea :disabled="form.a4.disabled" class="form-control" rows="5" v-model="inputDraftListA4.note"></textarea>
                    </base-input>
                </div>
            </div>

            <template slot="footer">
                <base-button type="secondary" @click="form.a4.show = false">{{ tt('close') }}</base-button>
                <base-button v-if="form.a4.type == 'add'" type="primary" @click="saveA4('add')">{{ tt('add') }}</base-button>
                <base-button v-else-if="form.a4.type == 'edit'" type="primary" @click="saveA4('edit')">{{ tt('edit') }}</base-button>
            </template>
        </modal>
        
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import draftList from '@/services/material/draftList.service';
    import materialType from '@/services/master/materialType.service';
    import plantCode from '@/services/master/plantCode.service';
    import unitOfMeasure from '@/services/dictionary/unitOfMeasure.service';
    import manufacture from '@/services/master/manufacture.service';
    import manrefType from '@/services/master/manrefType.service';
    import equipmentCode from '@/services/master/equipmentCode.service';
    import abcIndicator from '@/services/master/abcIndicator.service';
    import mrpTypes from '@/services/master/mrpTypes.service';
    import mrpController from '@/services/master/mrpController.service';
    import valuationClass from '@/services/master/valuationClass.service';
    import valuationCategory from '@/services/master/valuationCategory.service';
    import valuationType from '@/services/master/valuationType.service';
    import priceControl from '@/services/master/priceControl.service';
    import salesOrganization from '@/services/master/salesOrganization.service';
    import distributionChannel from '@/services/master/distributionChannel.service';
    import division from '@/services/master/division.service';
    import taxClassification from '@/services/master/taxClassification.service';
    import itemCategoryGroup from '@/services/master/itemCategoryGroup.service';
    import accountAssignmentGroup from '@/services/master/accountAssignmentGroup.service';
    import transportationGroup from '@/services/master/transportationGroup.service';
    import loadingGroup from '@/services/master/loadingGroup.service';
    import profitCenter from '@/services/master/profitCenter.service';
    import warehouse from '@/services/master/warehouse.service';
    import storageType from '@/services/master/storageType.service';
    import materialNumber from '@/services/master/materialNumber.service';
    import locationCode from '@/services/master/locationCode.service';
    import catalogType from '@/services/master/catalogType.service';
    import materialGroup from '@/services/master/materialGroup.service';
    import endUserSearchMaterial from '@/services/endUserSearch/endUserSearchMaterial.service';
    import drawingMaster from '@/services/dictionary/drawingMaster.service';
    import standardItemName from '@/services/dictionary/standardItemName.service';
    import characteristicItemName from '@/services/dictionary/characteristicItemName.service';
    import lotSize from '@/services/master/lotSize.service';
    import procurementType from '@/services/master/procurementType.service';
    import specialProcurementType from '@/services/master/specialProcurementType.service';
    import scopeOfSupply from '@/services/dictionary/scopeofSupply.service';
    import natoGroupClass from '@/services/dictionary/natoGroupClass.service';

    export default {        
        data() {
            return { 
                formTracking: {
                    show: false
                },
                loadTimeout: null,  
                btnUpdate: {
                    onLoading: false
                },
                btnSend: {
                    onLoading: false
                },         
                formMcrInformation: {
                    show: false
                },                
                form: {
                    a4: {
                        show: false,
                        disabled: false,
                        type: 'add',
                    },
                },
                formDetail: {
                    show: false
                }, 
                mcrType: 'a1',  
                inputDraftList: {

                },
                
                inputDraftListA4: {
                    material_number: '',
                    plant_code: '',
                    location_code: '',
                    valuation_area_code: '',
                    valuation_type_code: '',
                    sales_org_code: '',
                    distr_channel_code: '',
                    warehouse_number: '',
                    storage_type_code: '',
                    reason: '',
                    note: '',
                    deletion_level: '',
                },
                draftList: {},
                mcrInformation: {},
                mcrInformationEdit: {
                    new_subject: ''
                },
                parameter: {},
                category: {},
                mcrItem: [],
                materialType: {},
                plantCode: {},
                unitOfMeasure: {},
                manufacture: {},
                manrefType: {},
                equipmentCode: {},
                abcIndicator: {},
                mrpTypes: {},
                mrpController: {},
                valuationClass: {},
                valuationCategory: {},
                valuationType: {},
                priceControl: {},
                salesOrganization: {},
                distributionChannel: {},
                division: {},
                taxClassification: {},
                itemCategoryGroup: {},
                accountAssignmentGroup: {},
                transportationGroup: {},
                loadingGroup: {},
                profitCenter: {},
                warehouse: {},
                storageType: {},
                materialNumber: {},
                locationCode: {},
                materialGroup: {},
                catalogType: {},
                itemType: {},
                fabNonFab: {},
                bomNonBom: {},
                deletionLevel: {},
                drawingMaster: {},
                standardItemName: [],
                characteristicItemName: [],
                characteristicValue: [],
                lotSize: [],
                procurementType: [],
                specialProcurementType: [],
                scopeOfSupply: [],
                natoGroupClass: [],
                manufacturePartNumber: {
                    manufacture_code: [],
                    mpn: [],
                    manufacture_type: [],
                    manufacture_note: []
                },
                manufacturePartNumberA4: {
                    mpn: [],
                },
                equipmentQuantityInstall: {
                    equipment_code: [],
                    qty_installed: [],
                    drawing_number: [],
                },
                equipmentQuantityInstallA4: {
                    equipment_code: [],
                },
                storageLocation: {
                    plant: [],
                    storage_location: [],
                    warehouse_number: [],
                    storage_type: [],
                    bin_location: [],
                },
                tempSales: {
                    sales_org: '',
                    distr_channel: '',
                    division: '',
                    tax_cat: '',
                    tax_class: '',
                    gen_item_cat_grp: '',
                    account_assign_grp: '',
                    item_cat_grp: '',
                    trans_group: '',
                    loading_group: '',
                    profit_center: '',
                },
                sales: {
                    sales_org: [],
                    distr_channel: [],
                    division: [],
                    tax_cat: [],
                    tax_class: [],
                    gen_item_cat_grp: [],
                    account_assign_grp: [],
                    item_cat_grp: [],
                    trans_group: [],
                    loading_group: [],
                    profit_center: [],
                },
                tempMrp: {
                    abc_indicator: '',
                    mrp_type: '',
                    mrp_controller: '',
                    lot_size: '',
                    min: '',
                    max: '',
                    procurement_type: '',
                    special_proc: '',
                },
                mrp: {
                    abc_indicator: [],
                    mrp_type: [],
                    mrp_controller: [],
                    lot_size: [],
                    min: [],
                    max: [],
                    procurement_type: [],
                    special_proc: [],
                },
                tempAccounting: {
                    valuation_class: '',
                    valuation_category: '',
                    valuation_type: '',
                    price_determination: '',
                    price_unit: '',
                },
                accounting_valuation_class: [],
                accounting_valuation_category: [],
                accounting_valuation_type: [],
                accounting_price_determination: [],
                accounting_price_unit: [],
                accounting: {
                    valuation_class: [],
                    valuation_category: [],
                    valuation_type: [],
                    price_determination: [],
                    price_unit: [],
                },
                accordion: {
                    a4: {
                        mpn: {
                            show: ''
                        },
                        eqi: {
                            show: ''
                        },
                    },
                },
                mcr_code: window.location.hash.split('/')[3],
                token: window.location.hash.split('/')[4],
                formtype: window.location.hash.split("/")[5],
                total_mcr_item: '',
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            this.get()
            /*this.getMaterialType()//a1
            this.getPlantCode()//a1,a4
            this.getUnitOfMeasure()//a1
            this.getManufacture()//a1
            this.getManrefType()//a1
            this.getEquipmentCode()//a1
            this.getAbcIndicator()//a2
            this.getMrpTypes()//a2
            this.getMrpController()//b2
            this.getValuationClass()//b2
            this.getValuationCategory()//b2
            this.getValuationType()//a3,a4
            this.getPriceControl()//b2
            this.getSalesOrganization()//a4
            this.getDistributionChannel()//a4
            this.getDivision()//b2
            this.getTaxClassification()//b2
            this.getItemCategoryGroup()//b2
            this.getAccountAssignmentGroup()//b2
            this.getTransportationGroup()//b2
            this.getLoadingGroup()//b2
            this.getProfitCenter()//b2
            this.getWarehouse()//a4
            this.getStorageType()//a4
            //this.getMaterialNumber()//a1,a4
            this.getLocationCode()//a3,a4
            // this.getCatalogType()
            this.getMaterialGroup()
            this.getDrawingMaster()//a1
            this.getStandardItemName()//b2
            this.getLotSize()//b2
            this.getProcurementType()//b2
            this.getSpecialProcurementType()//b2*/
        },
        methods: {
            get() {
                let context = this;               
                Api(context, draftList.getMcrItem(context.mcr_code, context.token)).onSuccess(function(response) {                 
                    context.mcrItem = response.data.data.mcr_item;
                    context.mcrInformation = response.data.data.mcr[0];
                    context.parameter = response.data.data.parameter;
                    context.category = response.data.data.category;
                    context.itemType = response.data.data.item_type;
                    context.fabNonFab = response.data.data.fab_non_fab;
                    context.bomNonBom = response.data.data.bom_non_bom;
                    context.deletionLevel = response.data.data.deletion_level;
                    context.mcrInformationEdit.new_subject = response.data.data.mcr[0].subject;  
                    context.total_mcr_item = response.data.data.mcr_item.length;                    
                })
                .call()
            },
            getMaterialType() {
                let context = this;               
                Api(context, materialType.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.materialType = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.materialType = [];
                    }
                })
                .call()
            },
            getPlantCode() {
                let context = this;               
                Api(context, plantCode.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.plantCode = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.plantCode = [];
                    }
                })
                .call()
            },
            getUnitOfMeasure() {
                let context = this;               
                Api(context, unitOfMeasure.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.unitOfMeasure = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.unitOfMeasure = [];
                    }
                })
                .call()
            },
            getManufacture() {
                let context = this;               
                Api(context, manufacture.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.manufacture = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.manufacture = [];
                    }
                })
                .call()
            },
            getManrefType() {
                let context = this;               
                Api(context, manrefType.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.manrefType = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.manrefType = [];
                    }
                })
                .call()
            },
            getEquipmentCode() {
                let context = this;               
                Api(context, equipmentCode.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.equipmentCode = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.equipmentCode = [];
                    }
                })
                .call()
            },
            getAbcIndicator() {
                let context = this;               
                Api(context, abcIndicator.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.abcIndicator = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.abcIndicator = [];
                    }
                })
                .call()
            },
            getMrpTypes() {
                let context = this;               
                Api(context, mrpTypes.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.mrpTypes = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.mrpTypes = [];
                    }
                })
                .call()
            },
            getMrpController() {
                let context = this;               
                Api(context, mrpController.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.mrpController = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.mrpController = [];
                    }
                })
                .call()
            },
            getValuationClass() {
                let context = this;               
                Api(context, valuationClass.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.valuationClass = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.valuationClass = [];
                    }
                })
                .call()
            },
            getValuationCategory() {
                let context = this;               
                Api(context, valuationCategory.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.valuationCategory = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.valuationCategory = [];
                    }
                })
                .call()
            },
            getValuationType() {
                let context = this;               
                Api(context, valuationType.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.valuationType = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.valuationType = [];
                    }
                })
                .call()
            },
            getPriceControl() {
                let context = this;               
                Api(context, priceControl.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.priceControl = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.priceControl = [];
                    }
                })
                .call()
            },
            getSalesOrganization() {
                let context = this;               
                Api(context, salesOrganization.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.salesOrganization = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.salesOrganization = [];
                    }
                })
                .call()
            },
            getDistributionChannel() {
                let context = this;               
                Api(context, distributionChannel.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.distributionChannel = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.distributionChannel = [];
                    }
                })
                .call()
            },
            getDivision() {
                let context = this;               
                Api(context, division.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.division = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.division = [];
                    }
                })
                .call()
            },
            getTaxClassification() {
                let context = this;               
                Api(context, taxClassification.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.taxClassification = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.taxClassification = [];
                    }
                })
                .call()
            },
            getItemCategoryGroup() {
                let context = this;               
                Api(context, itemCategoryGroup.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.itemCategoryGroup = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.itemCategoryGroup = [];
                    }
                })
                .call()
            },
            getAccountAssignmentGroup() {
                let context = this;               
                Api(context, accountAssignmentGroup.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.accountAssignmentGroup = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.accountAssignmentGroup = [];
                    }
                })
                .call()
            },
            getTransportationGroup() {
                let context = this;               
                Api(context, transportationGroup.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.transportationGroup = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.transportationGroup = [];
                    }
                })
                .call()
            },
            getLoadingGroup() {
                let context = this;               
                Api(context, loadingGroup.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.loadingGroup = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.loadingGroup = [];
                    }
                })
                .call()
            },
            getProfitCenter() {
                let context = this;               
                Api(context, profitCenter.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.profitCenter = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.profitCenter = [];
                    }
                })
                .call()
            },
            getWarehouse() {
                let context = this;               
                Api(context, warehouse.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.warehouse = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.warehouse = [];
                    }
                })
                .call()
            },
            getStorageType() {
                let context = this;               
                Api(context, storageType.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.storageType = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.storageType = [];
                    }
                })
                .call()
            },
            getMaterialNumber() {
                let context = this;               
                Api(context, materialNumber.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.materialNumber = response.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.materialNumber = [];
                    }
                })
                .call()
            },
            getLocationCode() {
                let context = this;               
                Api(context, locationCode.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.locationCode = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.locationCode = [];
                    }
                })
                .call()
            },
            getCatalogType() {
                let context = this;               
                Api(context, catalogType.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.catalogType = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.catalogType = [];
                    }
                })
                .call()
            },
            getMaterialGroup() {
                let context = this;               
                Api(context, materialGroup.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.materialGroup = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.materialGroup = [];
                    }
                })
                .call()
            },
            getDrawingMaster() {
                let context = this;               
                Api(context, drawingMaster.get({per_page: 'none'})).onSuccess(function(response) {    
                    context.drawingMaster = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.drawingMaster = [];
                    }
                })
                .call()
            },
            getStandardItemName() {
                let context = this;               
                Api(context, standardItemName.get()).onSuccess(function(response) {    
                    context.standardItemName = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.standardItemName = [];
                    }
                })
                .call()
            },
            getLotSize() {
                let context = this;               
                Api(context, lotSize.get()).onSuccess(function(response) {    
                    context.lotSize = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.lotSize = [];
                    }
                })
                .call()
            },
            getProcurementType() {
                let context = this;               
                Api(context, procurementType.get()).onSuccess(function(response) {    
                    context.procurementType = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.procurementType = [];
                    }
                })
                .call()
            },
            getSpecialProcurementType() {
                let context = this;               
                Api(context, specialProcurementType.get()).onSuccess(function(response) {    
                    context.specialProcurementType = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.specialProcurementType = [];
                    }
                })
                .call()
            },
            getScopeOfSupply() {
                let context = this;               
                Api(context, scopeOfSupply.get()).onSuccess(function(response) {    
                    context.scopeOfSupply = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.scopeOfSupply = [];
                    }
                })
                .call()
            },
            getNatoGroupClass() {
                let context = this;               
                Api(context, natoGroupClass.get()).onSuccess(function(response) {    
                    context.natoGroupClass = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.natoGroupClass = [];
                    }
                })
                .call()
            },
            handleTableAction(command) {
                this.formTracking.show = false;
                switch (command.action) {
                    case 'detail':
                            this.detail(command.data);
                        break;
                    case 'remove':
                            this.remove(command.data);
                        break;
                    default:
                        break;
                }
            },
            detail(data) {
                let context = this;

                if (data.mcr_type == 'MCR01') {
                    data.mcr_type = 'a1';
                } else if (data.mcr_type == 'MCR02') {
                    data.mcr_type = 'a2';
                } else if (data.mcr_type == 'MCR03') {
                    data.mcr_type = 'a3';
                } else if (data.mcr_type == 'MCR04') {
                    data.mcr_type = 'a4';
                }

                if (data.mcr_type == 'a1') {
                    if (data.btn == 'detail') {
                        context.form.a1.show = true;
                        context.form.a1.disabled = true;
                        context.form.a1.type = 'detail';
                    } else {
                        context.form.a1.show = true;
                        context.form.a1.disabled = false;
                        context.form.a1.type = 'edit';
                    }

                    Api(context, draftList.getDetailMcrItem(data.mcr_item_code)).onSuccess(function(response) {    
                        context.inputDraftListA1 = response.data.data[0];
                        context.inputDraftListA1.uom = response.data.data[0].uom_code;
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.inputDraftListA1 = [];
                        }
                    }).call()

                    Api(context, draftList.getMcrItemMpn(data.mcr_item_code)).onSuccess(function(response) {
                        for (var i=0; i<response.data.data.length; i++) {
                            context.manufacturePartNumberA1.manufacture_code[i] = response.data.data[i].manufacture_code;
                            context.manufacturePartNumberA1.mpn[i] = response.data.data[i].mpn;
                            context.manufacturePartNumberA1.manufacture_type[i] = response.data.data[i].manufacture_type;
                            context.manufacturePartNumberA1.manufacture_note[i] = response.data.data[i].manufacture_note;
                        }                        
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.manufacturePartNumberA1.manufacture_code = [];
                            context.manufacturePartNumberA1.mpn = [];
                            context.manufacturePartNumberA1.manufacture_type = [];
                            context.manufacturePartNumberA1.manufacture_note = [];
                        }
                    }).call()

                    Api(context, draftList.getMcrItemEquipment(data.mcr_item_code)).onSuccess(function(response) {
                        for (var i=0; i<response.data.data.length; i++) {
                            context.equipmentQuantityInstallA1.equipment_code[i] = response.data.data[i].equipment_code;
                            context.equipmentQuantityInstallA1.qty_installed[i] = response.data.data[i].qty_installed;
                            context.equipmentQuantityInstallA1.drawing_number[i] = response.data.data[i].drawing_number;
                        }                        
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.equipmentQuantityInstallA1.equipment_code = [];
                            context.equipmentQuantityInstallA1.qty_installed = [];
                            context.equipmentQuantityInstallA1.drawing_number = [];
                        }
                    }).call()

                    Api(context, draftList.getMcrItemAttachment(data.mcr_item_code)).onSuccess(function(response) {
                        context.attachmentDocumentA1.file_attachment_name = response.data.data;
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.attachmentDocumentA1.file_attachment_name = [];
                        }
                    }).call()
                } else if (data.mcr_type == 'a2') {
                    if (data.btn == 'detail') {
                        context.form.a2.show = true;
                        context.form.a2.disabled = true;
                        context.form.a2.type = 'detail';
                    } else {
                        context.form.a2.show = true;
                        context.form.a2.disabled = false;
                        context.form.a2.type = 'edit';
                    }

                    Api(context, draftList.getDetailMcrItem(data.mcr_item_code)).onSuccess(function(response) {    
                        context.inputDraftListA2 = response.data.data[0];
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.inputDraftListA2 = [];
                        }
                    }).call()

                    Api(context, draftList.getMcrItemMpn(data.mcr_item_code)).onSuccess(function(response) {
                        for (var i=0; i<response.data.data.length; i++) {
                            context.manufacturePartNumberA2.manufacture_code[i] = response.data.data[i].manufacture_code;
                            context.manufacturePartNumberA2.mpn[i] = response.data.data[i].mpn;
                            context.manufacturePartNumberA2.manufacture_type[i] = response.data.data[i].manufacture_type;
                            context.manufacturePartNumberA2.manufacture_note[i] = response.data.data[i].manufacture_note;
                        }                        
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.manufacturePartNumberA2.manufacture_code = [];
                            context.manufacturePartNumberA2.mpn = [];
                            context.manufacturePartNumberA2.manufacture_type = [];
                            context.manufacturePartNumberA2.manufacture_note = [];
                        }
                    }).call()

                    Api(context, draftList.getMcrItemEquipment(data.mcr_item_code)).onSuccess(function(response) {
                        for (var i=0; i<response.data.data.length; i++) {
                            context.equipmentQuantityInstallA2.plant[i] = response.data.data[i].plant_code;
                            context.equipmentQuantityInstallA2.equipment_code[i] = response.data.data[i].equipment_code;
                            context.equipmentQuantityInstallA2.qty_installed[i] = response.data.data[i].qty_installed;
                            context.equipmentQuantityInstallA2.drawing_number[i] = response.data.data[i].drawing_number;
                        }                        
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.equipmentQuantityInstallA2.plant = [];
                            context.equipmentQuantityInstallA2.equipment_code = [];
                            context.equipmentQuantityInstallA2.qty_installed = [];
                            context.equipmentQuantityInstallA2.drawing_number = [];
                        }
                    }).call()
                } else if (data.mcr_type == 'a3') {
                    if (data.btn == 'detail') {
                        context.form.a3.show = true;
                        context.form.a3.disabled = true;
                        context.form.a3.type = 'detail';
                    } else {
                        context.form.a3.show = true;
                        context.form.a3.disabled = false;
                        context.form.a3.type = 'edit';
                    }

                    Api(context, draftList.getDetailMcrItem(data.mcr_item_code)).onSuccess(function(response) {    
                        context.inputDraftListA3 = response.data.data[0];                        
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.inputDraftListA3 = [];
                        }
                    }).call()

                    Api(context, draftList.getMcrItemMpn(data.mcr_item_code)).onSuccess(function(response) {
                        for (var i=0; i<response.data.data.length; i++) {
                            context.manufacturePartNumberA3.manufacture_code[i] = response.data.data[i].manufacture_code;
                            context.manufacturePartNumberA3.mpn[i] = response.data.data[i].mpn;
                            context.manufacturePartNumberA3.manufacture_type[i] = response.data.data[i].manufacture_type;
                            context.manufacturePartNumberA3.manufacture_note[i] = response.data.data[i].manufacture_note;
                        }                    
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.manufacturePartNumberA3.manufacture_code = [];
                            context.manufacturePartNumberA3.mpn = [];
                            context.manufacturePartNumberA3.manufacture_type = [];
                            context.manufacturePartNumberA3.manufacture_note = [];
                        }
                    }).call()

                    Api(context, draftList.getMcrItemEquipment(data.mcr_item_code)).onSuccess(function(response) {
                        for (var i=0; i<response.data.data.length; i++) {
                            context.equipmentQuantityInstallA3.plant[i] = response.data.data[i].plant_code;
                            context.equipmentQuantityInstallA3.equipment_code[i] = response.data.data[i].equipment_code;
                            context.equipmentQuantityInstallA3.qty_installed[i] = response.data.data[i].qty_installed;
                            context.equipmentQuantityInstallA3.drawing_number[i] = response.data.data[i].drawing_number;
                        }                        
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.equipmentQuantityInstallA3.plant = [];
                            context.equipmentQuantityInstallA3.equipment_code = [];
                            context.equipmentQuantityInstallA3.qty_installed = [];
                            context.equipmentQuantityInstallA3.drawing_number = [];
                        }
                    }).call()
                } else if (data.mcr_type == 'a4') {
                    if (data.btn == 'detail') {
                        context.form.a4.show = true;
                        context.form.a4.disabled = true;
                        context.form.a4.type = 'detail';
                    } else {
                        context.form.a4.show = true;
                        context.form.a4.disabled = false;
                        context.form.a4.type = 'edit';
                    }

                    Api(context, draftList.getDetailMcrItem(data.mcr_item_code)).onSuccess(function(response) {
                        context.inputDraftListA4.id = response.data.data[0].id;
                        context.inputDraftListA4.deletion_level = response.data.data[0].deletion_level;
                        context.inputDraftListA4.material_number = response.data.data[0].material_number;
                        context.inputDraftListA4.plant_code = response.data.data[0].plant_code;
                        context.inputDraftListA4.location_code = response.data.data[0].location_code;
                        context.inputDraftListA4.valuation_area_code = response.data.data[0].plant_code;
                        context.inputDraftListA4.valuation_type_code = response.data.data[0].valuation_type_code;
                        context.inputDraftListA4.sales_org_code = response.data.data[0].sales_org_code;
                        context.inputDraftListA4.distr_channel_code = response.data.data[0].distr_channel_code;
                        context.inputDraftListA4.reason = response.data.data[0].reason;
                        context.inputDraftListA4.note = response.data.data[0].note;
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.inputDraftListA4 = [];
                        }
                    }).call()

                    Api(context, draftList.getMcrItemMpn(data.mcr_item_code)).onSuccess(function(response) {
                        for (var i=0; i<response.data.data.length; i++) {
                            context.manufacturePartNumberA4.mpn[i] = response.data.data[i].mpn;
                        }                        
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.manufacturePartNumberA4.mpn = [];
                        }
                    }).call()

                    Api(context, draftList.getMcrItemEquipment(data.mcr_item_code)).onSuccess(function(response) {
                        for (var i=0; i<response.data.data.length; i++) {
                            context.equipmentQuantityInstallA4.equipment_code[i] = response.data.data[i].equipment_code;
                        }                        
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.equipmentQuantityInstallA4.equipment_code = [];
                        }
                    }).call()
                } else if (data.mcr_type == 'b1') {
                    if (data.btn == 'detail') {
                        context.form.b1.show = true;
                        context.form.b1.disabled = true;
                        context.form.b1.type = 'detail';
                    } else {
                        context.form.b1.show = true;
                        context.form.b1.disabled = false;
                        context.form.b1.type = 'edit';
                    }

                    Api(context, draftList.getDetailMcrItem(data.mcr_item_code)).onSuccess(function(response) {    
                        context.inputDraftListB1.id = response.data.data[0].id;
                        context.inputDraftListB1.description = response.data.data[0].specification;
                        context.inputDraftListB1.reason = response.data.data[0].reason;
                        context.inputDraftListB1.po_text_memo = response.data.data[0].note;
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.inputDraftListB1 = [];
                        }
                    }).call()
                    
                    Api(context, draftList.getMcrItemParameter(data.mcr_item_code)).onSuccess(function(response) {
                        for (var i=0; i<response.data.data.length; i++) {
                            context.paramsB1.parameter[i] = response.data.data[i].parameter;
                            context.paramsB1.category[i] = response.data.data[i].category;
                            context.paramsB1.description[i] = response.data.data[i].description;
                            // alert(response.data.data[i].parameter)
                        }                        
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.paramsB1.parameter = [];
                            context.paramsB1.category = [];
                            context.paramsB1.description = [];
                        }
                    }).call()

                    this.form.b1.show = true;
                } else if (data.mcr_type == 'b2') {
                    this.form.b2.show = true;
                } else if (data.mcr_type == 'b3') {
                    this.form.b3.show = true;
                } else if (data.mcr_type == 'b4') {
                    this.form.b4.show = true;
                } else if (data.mcr_type == 'b6') {
                    this.form.b6.show = true;
                }
            },
            remove(data) {
                let context = this;
                context.confirmDialog(context.tt('confirm_delete')).then((result) => {
                    if (result.value) {
                        Api(context, draftList.deleteMcrItem(data.id)).onSuccess(function(response) {
                            context.$notify({
                                message: response.data.message,
                                type: 'success'
                            });
                            context.get();
                        }).call();
                    }
                })
            },
            create(key) {
                this.inputDraftListA4.material_number = '';
                this.inputDraftListA4.plant_code = '';
                this.inputDraftListA4.location_code = '';
                this.inputDraftListA4.valuation_type_code = '';
                this.inputDraftListA4.valuation_area_code = '';
                this.inputDraftListA4.sales_org_code = '';
                this.inputDraftListA4.distr_channel_code = '';
                this.inputDraftListA4.warehouse_number = '';
                this.inputDraftListA4.storage_type_code = '';
                this.manufacturePartNumberA4.mpn = [];
                this.equipmentQuantityInstallA4.equipment_code = [];
                this.inputDraftListA4.reason = '';
                this.inputDraftListA4.note = '';

                this.form.a4.show = true;
                this.form.a4.disabled = false;
                this.form.a4.type = 'add';

                this.getMaterialNumber();
                this.getPlantCode();
                this.getLocationCode();
                this.getValuationType();
                this.getSalesOrganization();
                this.getDistributionChannel()
                this.getWarehouse()//a4
                this.getStorageType()//a4
                this.getManufacture();
                this.getEquipmentCode();
                this.getDrawingMaster();
                this.getManrefType();
            },
            addMpnA4() {
                if (this.manufacturePartNumberA4.mpn.length == 0) {
                    this.manufacturePartNumberA4.mpn.push('')
                } else if (this.manufacturePartNumberA4.mpn.length > 0) {
                    if (this.manufacturePartNumberA4.mpn[this.manufacturePartNumberA4.mpn.length-1] != '') {
                        
                        this.manufacturePartNumberA4.mpn.push('')
                    } else {
                        this.$notify({
                            message: 'Please complete data!',                  
                            type: 'danger'
                        });
                    }
                }
            },
            removeMpnA4(key) {
                this.manufacturePartNumberA4.mpn.splice(key, 1)
            },
            addMpn() {
                this.manufacturePartNumber.manufacture_code.push('')
                this.manufacturePartNumber.mpn.push('')
                this.manufacturePartNumber.manufacture_type.push('')
                this.manufacturePartNumber.manufacture_note.push('')
            },
            removeMpn(key) {
                this.manufacturePartNumber.manufacture_code.splice(key, 1)
                this.manufacturePartNumber.mpn.splice(key, 1)
                this.manufacturePartNumber.manufacture_type.splice(key, 1)
                this.manufacturePartNumber.manufacture_note.splice(key, 1)
            },
            addEqi() {
                this.equipmentQuantityInstall.equipment_code.push('')
                this.equipmentQuantityInstall.qty_installed.push('')
                this.equipmentQuantityInstall.drawing_number.push('')
            },
            removeEqi(key) {
                this.equipmentQuantityInstall.equipment_code.splice(key, 1)
                this.equipmentQuantityInstall.qty_installed.splice(key, 1)
                this.equipmentQuantityInstall.drawing_number.splice(key, 1)
            },
            addEqiA4() {
                if (this.equipmentQuantityInstallA4.equipment_code.length == 0) {
                    this.equipmentQuantityInstallA4.equipment_code.push('')
                } else if (this.equipmentQuantityInstallA4.equipment_code.length > 0) {
                    if (this.equipmentQuantityInstallA4.equipment_code[this.equipmentQuantityInstallA4.equipment_code.length-1] != '') {
                        
                        this.equipmentQuantityInstallA4.equipment_code.push('')
                    } else {
                        this.$notify({
                            message: 'Please complete data!',                  
                            type: 'danger'
                        });
                    }
                }
            },
            removeEqiA4(key) {
                this.equipmentQuantityInstallA4.equipment_code.splice(key, 1)                
            },
            addAd() {
                this.attachmentDocumentA1.file_attachment_name.push('')
            },
            removeAd(key) {
                this.attachmentDocumentA1.file_attachment_name.splice(key, 1)
            },
            addPl() {
                this.plantLevel.plant.push('')
            },
            removePl(key) {
                this.plantLevel.plant.splice(key, 1)
            },
            addS() {
                if (this.tempSales.sales_org != '' && this.tempSales.distr_channel != '' && this.tempSales.division != '' && this.tempSales.tax_cat != '' && this.tempSales.tax_class != '' && this.tempSales.gen_item_cat_grp != '' && this.tempSales.account_assign_grp != '' && this.tempSales.item_cat_grp != '' && this.tempSales.trans_group != '' && this.tempSales.loading_group != '' && this.tempSales.profit_center) {
                    this.sales.sales_org.push(this.tempSales.sales_org)
                    this.sales.distr_channel.push(this.tempSales.distr_channel)
                    this.sales.division.push(this.tempSales.division)
                    this.sales.tax_cat.push(this.tempSales.tax_cat)
                    this.sales.tax_class.push(this.tempSales.tax_class)
                    this.sales.gen_item_cat_grp.push(this.tempSales.gen_item_cat_grp)
                    this.sales.account_assign_grp.push(this.tempSales.account_assign_grp)
                    this.sales.item_cat_grp.push(this.tempSales.item_cat_grp)
                    this.sales.trans_group.push(this.tempSales.trans_group)
                    this.sales.loading_group.push(this.tempSales.loading_group)
                    this.sales.profit_center.push(this.tempSales.profit_center)

                    this.tempSales = {
                        sales_org: '',
                        distr_channel: '',
                        division: '',
                        tax_cat: '',
                        tax_class: '',
                        gen_item_cat_grp: '',
                        account_assign_grp: '',
                        item_cat_grp: '',
                        trans_group: '',
                        loading_group: '',
                        profit_center: '',
                    }
                } else {                    
                    this.$notify({
                        message: 'Please complete data!',                  
                        type: 'danger'
                    });
                }
            },
            removeS(key) {
                this.sales.sales_org.splice(key, 1)
                this.sales.distr_channel.splice(key, 1)
                this.sales.division.splice(key, 1)
                this.sales.tax_cat.splice(key, 1)
                this.sales.tax_class.splice(key, 1)
                this.sales.gen_item_cat_grp.splice(key, 1)
                this.sales.account_assign_grp.splice(key, 1)
                this.sales.item_cat_grp.splice(key, 1)
                this.sales.trans_group.splice(key, 1)
                this.sales.loading_group.splice(key, 1)
                this.sales.profit_center.splice(key, 1)
            },
            addM() {                
                if (this.tempMrp.abc_indicator != '' && 
                    this.tempMrp.mrp_type != '' && 
                    this.tempMrp.mrp_controller != '' && 
                    this.tempMrp.lot_size != '' && 
                    this.tempMrp.min != '' && 
                    this.tempMrp.max != '' && 
                    this.tempMrp.procurement_type != '' && 
                    this.tempMrp.special_proc) {

                    this.mrp.abc_indicator.push(this.tempMrp.abc_indicator)
                    this.mrp.mrp_type.push(this.tempMrp.mrp_type)
                    this.mrp.mrp_controller.push(this.tempMrp.mrp_controller)
                    this.mrp.lot_size.push(this.tempMrp.lot_size)
                    this.mrp.min.push(this.tempMrp.min)
                    this.mrp.max.push(this.tempMrp.max)
                    this.mrp.procurement_type.push(this.tempMrp.procurement_type)
                    this.mrp.special_proc.push(this.tempMrp.special_proc)

                    this.tempMrp = {
                        abc_indicator: '',
                        mrp_type: '',
                        mrp_controller: '',
                        lot_size: '',
                        min: '',
                        max: '',
                        procurement_type: '',
                        special_proc: '',
                    }
                } else {
                    this.$notify({
                        message: 'Please complete data!',                  
                        type: 'danger'
                    });
                }
            },
            removeM(key) {
                this.mrp.abc_indicator.splice(key, 1)
                this.mrp.mrp_type.splice(key, 1)
                this.mrp.mrp_controller.splice(key, 1)
                this.mrp.lot_size.splice(key, 1)
                this.mrp.min.splice(key, 1)
                this.mrp.max.splice(key, 1)
                this.mrp.procurement_type.splice(key, 1)
                this.mrp.special_proc.splice(key, 1)
            },
            addA() {                
                if (this.tempAccounting.valuation_class != '' && 
                    this.tempAccounting.valuation_category != '' && 
                    this.tempAccounting.valuation_type != '' && 
                    this.tempAccounting.price_determination != '' && 
                    this.tempAccounting.price_unit) {

                    this.accounting.valuation_class.push(this.tempAccounting.valuation_class)
                    this.accounting.valuation_category.push(this.tempAccounting.valuation_category)
                    this.accounting.valuation_type.push(this.tempAccounting.valuation_type)
                    this.accounting.price_determination.push(this.tempAccounting.price_determination)
                    this.accounting.price_unit.push(this.tempAccounting.price_unit)

                    // this.tempAccounting = {
                    //     valuation_class: '',
                    //     valuation_category: '',
                    //     valuation_type: '',
                    //     price_determination: '',
                    // }
                } else {
                    this.$notify({
                        message: 'Please complete data!',                  
                        type: 'danger'
                    });
                }
            },
            removeA(key) {
                this.accounting.valuation_class.splice(key, 1)
                this.accounting.valuation_category.splice(key, 1)
                this.accounting.valuation_type.splice(key, 1)
                this.accounting.price_determination.splice(key, 1)
                this.accounting.price_unit.splice(key, 1)
            },
            editMcrInformation() {
                this.formMcrInformation.add = false;
                this.formMcrInformation.show = true;
            }, 
            updateMcrInformation() {
                let context = this;     
                // context.errorMessage = null;      
                context.btnUpdate.onLoading = true;    
                Api(context, draftList.updateMcrSubject(context.mcrInformation.mcr_code, context.mcrInformation.token, context.mcrInformationEdit)).onSuccess(function(response) {
                    context.mcrInformation.subject = context.mcrInformationEdit.new_subject;
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success'
                    });
                }).onFinish(function() {
                    context.btnUpdate.onLoading = false;
                    context.formMcrInformation.show = false;
                }).call()        
            },
            changeMaterialNumberA4() {         
                let context = this;
                Api(context, endUserSearchMaterial.partCharValueEndUser({material_number: context.inputDraftListA4.material_number})).onSuccess(function(response) {    
                    for (var i=0; i<response.data.data.part_manufacture_ref.length; i++) {
                        // alert(response.data.data.part_manufacture_ref[i].material_number_mpn)
                        context.manufacturePartNumberA4.mpn[i] = response.data.data.part_manufacture_ref[i].material_number_mpn;
                    }
                    for (var i=0; i<response.data.data.part_equipment_code.length; i++) {
                        context.equipmentQuantityInstallA4.equipment_code[i] = response.data.data.part_equipment_code[i].equipment_code;
                    }                    
                    // console.log(response.data.data.part_manufacture_ref)
                    
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        // context.materialGroup = [];
                    }
                })
                .call()
            },
            saveA4(save) {
                let context = this;               
                let data = {
                    mcr_code: context.mcr_code,
                    material_number: context.inputDraftListA4.material_number,
                    location_code: context.inputDraftListA4.location_code,
                    valuation_type_code: context.inputDraftListA4.valuation_type_code,
                    deletion_level: context.inputDraftListA4.deletion_level,
                    sales_org_code: context.inputDraftListA4.sales_org_code,
                    distr_channel_code: context.inputDraftListA4.distr_channel_code,
                    reason: context.inputDraftListA4.reason,
                    note: context.inputDraftListA4.note,
                }

                if (context.inputDraftListA4.deletion_level !== '') {
                    if (context.inputDraftListA4.deletion_level == 'Material') {
                        if (context.inputDraftListA4.material_number != '' && context.inputDraftListA4.reason != '') {
                            var validation = true;
                        } else {
                            var validation = false;
                        }
                    }
                    if (context.inputDraftListA4.deletion_level == 'Plant') {
                        if (context.inputDraftListA4.material_number != '' && context.inputDraftListA4.plant_code != '' && context.inputDraftListA4.reason != '') {
                            var validation = true;
                        } else {
                            var validation = false;
                        }
                    }
                    if (context.inputDraftListA4.deletion_level == 'Storage Location') {
                        if (context.inputDraftListA4.material_number != '' && context.inputDraftListA4.plant_code != '' && context.inputDraftListA4.location_code != '' && context.inputDraftListA4.reason != '') {
                            var validation = true;
                        } else {
                            var validation = false;
                        }
                    }
                    if (context.inputDraftListA4.deletion_level == 'Valuation') {
                        if (context.inputDraftListA4.material_number != '' && context.inputDraftListA4.valuation_area_code != '' && context.inputDraftListA4.reason != '' && context.inputDraftListA4.valuation_type_code != '') {
                            var validation = true;
                        } else {
                            var validation = false;
                        }
                    }
                    if (context.inputDraftListA4.deletion_level == 'Sales') {
                        if (context.inputDraftListA4.material_number != '' && context.inputDraftListA4.sales_org_code != '' && context.inputDraftListA4.distr_channel_code != '' && context.inputDraftListA4.reason != '') {
                            var validation = true;
                        } else {
                            var validation = false;
                        }
                    }
                } else {
                    var validation = false;
                }

                if (validation == true) {
                    for (var i=0; i<context.manufacturePartNumberA4.mpn.length; i++) {
                        if (context.manufacturePartNumberA4.mpn[i] != '') {
                            

                            data.mpn = context.manufacturePartNumberA4.mpn;

                            // formData.append('manufacture_code['+i+']', context.manufacturePartNumberA4.manufacture_code[i])
                            // formData.append('mpn['+i+']', context.manufacturePartNumberA4.mpn[i])
                            // formData.append('manufacture_type['+i+']', context.manufacturePartNumberA4.manufacture_type[i])
                            // formData.append('manufacture_note['+i+']', context.manufacturePartNumberA4.manufacture_note[i])
                        } else {
                            context.$notify({
                                message: 'Please complete data!',                  
                                type: 'danger'
                            });
                            return false;
                        }
                    }
                    
                    for (var i=0; i<context.equipmentQuantityInstallA4.equipment_code.length; i++) {
                        if (context.equipmentQuantityInstallA4.equipment_code[i] != '') {                    
                            data.equipment_code = context.equipmentQuantityInstallA4.equipment_code;

                            // formData.append('plant['+i+']', context.equipmentQuantityInstallA4.plant[i])
                            // formData.append('equipment_code['+i+']', context.equipmentQuantityInstallA4.equipment_code[i])
                            // formData.append('qty_installed['+i+']', context.equipmentQuantityInstallA4.qty_installed[i])
                            // formData.append('drawing_number['+i+']', context.equipmentQuantityInstallA4.drawing_number[i])
                        } else {
                            context.$notify({
                                message: 'Please complete data!',                  
                                type: 'danger'
                            });
                            return false;
                        }
                    }

                    if (context.inputDraftListA4.deletion_level == 'Plant' || context.inputDraftListA4.deletion_level == 'Storage Location') {
                        data.plant_code = context.inputDraftListA4.plant_code;
                    }else{
                        data.plant_code = context.inputDraftListA4.valuation_area_code;
                    }

                    let api = '';
                    if (save == 'add') {
                        api = Api(context, draftList.createNewMcrItemNoFormData(data));
                    } else {
                        api = Api(context, draftList.updateMcrItemNoFormData(data, context.inputDraftListA4.id));
                    }
                    
                    api.onSuccess(function(response) {    
                        context.get();
                        context.$notify({
                            message: response.data.message,                  
                            type: 'success'
                        });
                        context.form.a4.show = false;
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.draftList = [];
                        }
                    })
                    .call()
                } else {
                    context.$notify({
                        message: 'Please complete data!',                  
                        type: 'danger'
                    });
                }
            },
            sendMcr(mcr_code) {
              this.btnSend.onLoading = true;
              let api = "";
              let context = this;
              api = Api(context, draftList.sendMcr(mcr_code));
                api.onSuccess(function(response) {
                  context.$notify({
                    message: response.data.message,
                    type: "success"
                  });
                  context.$router.push('/material/draft-list');
                })
                .onFinish(function() {
                  context.btnSend.onLoading = false;
                })
                .call();
            },
            toggleAccordion(show, type) {
                if (type == 'mpnA4') {
                    if (show == '') {
                        this.accordion.a4.mpn.show = 'show'
                    } else {
                        this.accordion.a4.mpn.show = ''
                    }
                } else if (type == 'eqiA4') {
                    if (show == '') {
                        this.accordion.a4.eqi.show = 'show'
                    } else {
                        this.accordion.a4.eqi.show = ''
                    }
                }
            },
            tracking() {
                this.formTracking.show = false;
                this.formTracking.show = true;
            },
        }   
    };
</script>
<style></style>
